<template>
  <div>
    <div class="approval_search_container">
      <vs-row>
        <vs-col vs-w="12" vs-type="flex" vs-justify="flex-start" style="padding: 0px">
          <div class="enrollment_count">
            <p class="enrollment_text">Enrollment Approval</p>
            <p class="enrollment_text">{{ enrollment_count }}</p>
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col vs-w="8.9" vs-offset="0.1" class="mt-4">
          <vs-row class="my-6">
            <vs-col vs-w="1" vs-type="flex" vs-justify="flex-start" vs-align="center" class="none_padding">
              <p class="filter-heading">Filters</p>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2"
              class="enrollment-tree-drop none_padding" id="citys">
              <vs-dropdown vs-custom-content vs-trigger-click>
                <a class="a-icon a-iconns ele-inside-text alignment py-1" href.prevent @click="showTreeBox">
                  City
                  <i class="material-icons">expand_more</i>
                </a>
                <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
                  <treeselect v-model="initSelectedCity" :multiple="true" :options="treeDataCity" />
                </vs-dropdown-menu>
              </vs-dropdown>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2.5"
              class="enrollment-tree-drop none_padding" id="cc">
              <vs-dropdown vs-custom-content vs-trigger-click>
                <a class="a-icon a-iconns ele-inside-text alignment py-1" href.prevent @click="showTreeBox">
                  City Classification
                  <i class="material-icons">expand_more</i>
                </a>
                <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
                  <treeselect v-model="initSelectedCC" :multiple="true" :options="treeDataCC" />
                </vs-dropdown-menu>
              </vs-dropdown>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2"
              class="enrollment-tree-drop none_padding" id="spoc">
              <vs-dropdown vs-custom-content vs-trigger-click>
                <a class="a-icon a-iconns ele-inside-text py-1" href.prevent @click="showTreeBox">
                  SPOC
                  <i class="material-icons">expand_more</i>
                </a>
                <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
                  <treeselect v-model="initSelectedSpoc" :multiple="true" :options="treeDataSpoc" />
                </vs-dropdown-menu>
              </vs-dropdown>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2.5"
              class="enrollment-tree-drop none_padding" id="sc">
              <vs-dropdown vs-custom-content vs-trigger-click>
                <a class="a-icon a-iconns ele-inside-text alignment py-1" href.prevent @click="showTreeBox">
                  SPOC Classification
                  <i class="material-icons">expand_more</i>
                </a>
                <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
                  <treeselect v-model="initSelectedSC" :multiple="true" :options="treeDataSC" />
                </vs-dropdown-menu>
              </vs-dropdown>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2"
              class="enrollment-tree-drop none_padding" id="course">
              <vs-dropdown vs-custom-content vs-trigger-click>
                <a class="a-icon a-iconns ele-inside-text py-1" href.prevent @click="showTreeBox">
                  Course
                  <i class="material-icons">expand_more</i>
                </a>
                <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
                  <treeselect v-model="initCourses" :multiple="true" :options="treeDataCourses" />
                </vs-dropdown-menu>
              </vs-dropdown>
            </vs-col>
          </vs-row>

          <vs-row>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" class="none_padding mt-6" vs-w="1.1">
              <p class="filter-heading">Date Filter</p>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" class="none_padding mt-6" vs-w="3">
              <div style="display: inline-grid; width: 100%; margin-top: -13px">
                <label class="date_filter_heading ml-1">Enrollment Date Range</label>
                <date-range-picker ref="picker" :opens="'right'" :locale-data="{
                  firstDay: 1,
                  format: 'DD-MMM-YYYY',
                }" :minDate="null" :maxDate="null" :singleDatePicker="false" :timePicker="false"
                  :timePicker24Hour="true" :showWeekNumbers="false" :showDropdowns="false" :autoApply="false"
                  v-model="enrollmentDateRange" class="enrollment_date">
                </date-range-picker>
              </div>
            </vs-col>
            <!-- <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" class="none_padding mt-6" vs-offset="0.2"
              vs-w="2.5">
              <div style="display: inline-grid; width: 100%; margin-top: -13px">
                <label class="date_filter_heading">Last Call</label>
                <date-range-picker ref="picker" :opens="'right'" :locale-data="{
                  firstDay: 1,
                  format: 'DD-MMM-YYYY',
                }" :minDate="null" :maxDate="null" :singleDatePicker="false" :timePicker="false"
                  :timePicker24Hour="true" :showWeekNumbers="false" :showDropdowns="false" :autoApply="false"
                  v-model="lastCallDateateRange" class="enrollment_date">
                </date-range-picker>
              </div>
            </vs-col> -->
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" class="none_padding mt-6" vs-offset="0.2"
              vs-w="3">
              <div style="display: inline-grid; width: 100%; margin-top: -13px">
                <label class="date_filter_heading ml-1">Enrollment Month & Year</label>
                <datepicker placeholder="MM/YY" :minimumView="'month'" :maximumView="'month'"
                  class="enrollment-date-picker" :format="customFormatter" v-model="dates"></datepicker>
              </div>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="0.7" style="margin-top: 15px">
              <div>
                <vs-button color="#0044ba" icon="clear" size="small" @click="clearDateFilter"></vs-button>
              </div>
            </vs-col>
          </vs-row>

          <vs-row class="mt-6 mb-2">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" class="none_padding" vs-w="12">
              <div class="aplproval_search_criteria mt-3">
                <h6 class="Criteria_heading ml-2 mt-2">Search Criteria:</h6>
                <vs-row vs-w="12" class="mt-2">
                  <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-offset="0.1" vs-w="10.7">
                    <div style="display: inline-block; width: 100%">
                      <span v-for="chip in cityData" :key="chip">
                        <vs-chip v-if="chip !== 'divider'" class="criteria_chip" closable
                          @click="removechip(chip, 'city')" color="#EAE5FA">
                          <b>{{ chip }}</b>
                        </vs-chip>
                      </span>
                      <span v-for="chip in selectedCCData" :key="chip">
                        <vs-chip v-if="chip !== 'divider'" class="criteria_chip" closable @click="removechip(chip, 'cc')"
                          color="#FAE1FE">
                          <b>{{ chip }}</b>
                        </vs-chip>
                      </span>
                      <span v-for="chip in selectedSpocData" :key="chip">
                        <vs-chip v-if="chip !== 'divider'" class="criteria_chip" closable
                          @click="removechip(chip, 'spoc')" color="#BCEBFA">
                          <b>{{ chip }}</b>
                        </vs-chip>
                      </span>
                      <span v-for="chip in selectedSCData" :key="chip">
                        <vs-chip v-if="chip !== 'divider'" class="criteria_chip" closable @click="removechip(chip, 'sc')"
                          color="#B4EDFF">
                          <b>{{ chip }}</b>
                        </vs-chip>
                      </span>
                      <span v-for="chip in selectedCourseData" :key="chip">
                        <vs-chip v-if="chip !== 'divider'" class="criteria_chip" closable
                          @click="removechip(chip, 'course')" color="#FFC2C2">
                          <b>{{ chip }}</b>
                        </vs-chip>
                      </span>
                    </div>
                  </vs-col>
                  <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-start" vs-w="1.2">
                    <vs-button color="#0044ba" icon="search" @click="getNewEnrollementToApproval(ActiveNavBarTab, 1)"
                      size="small"></vs-button>
                    &nbsp;
                    <vs-button color="#0044ba" icon="refresh" size="small" @click="refreshData"></vs-button>
                  </vs-col>
                </vs-row>
              </div>
            </vs-col>
          </vs-row>
        </vs-col>
        <vs-col vs-w="3" vs-type="flex" vs-justify="center" vs-align="flex-start" style="margin-top: -1.5%">
          <vx-card class="approval_options_filter">
            <p class="approval-option-side mb-4">Options</p>
            <vs-row class="mb-4">
              <vs-col vs-type="flex" vs-justify="center" class="mb-2 no-padding">
                <vs-input placeholder="Can-ID" v-model="identity" class="approval-input-filter" />
                <vs-button style="position: absolute; right: 9%; color: #0044ba" color="#FFFFFF" type="gradient"
                  icon="search" @click="getNewEnrollementToApproval(ActiveNavBarTab, 1)"></vs-button>
              </vs-col>
            </vs-row>
            <vs-row class="mb-4">
              <vs-col vs-type="flex" vs-justify="center" class="mb-2 no-padding">
                <vs-input placeholder="Candidate Name" v-model="candidate_name" class="approval-input-filter" />
                <vs-button style="position: absolute; right: 9%; color: #0044ba" color="#FFFFFF" type="gradient"
                  icon="search" @click="getNewEnrollementToApproval(ActiveNavBarTab, 1)"></vs-button>
              </vs-col>
            </vs-row>
            <vs-row class="mb-4">
              <vs-col vs-type="flex" vs-justify="center" class="mb-2 no-padding">
                <vs-input placeholder="Contact Number" v-model="candidate_number" class="approval-input-filter" />
                <vs-button style="position: absolute; right: 9%; color: #0044ba" color="#FFFFFF" type="gradient"
                  icon="search" @click="getNewEnrollementToApproval(ActiveNavBarTab, 1)"></vs-button>
              </vs-col>
            </vs-row>
            <vs-row class="mb-3">
              <vs-col vs-type="flex" vs-justify="center" class="mb-2 no-padding">
                <vs-input placeholder="Email Address" v-model="candidate_email" class="approval-input-filter" />
                <vs-button style="position: absolute; right: 9%; color: #0044ba" color="#FFFFFF" type="gradient"
                  icon="search" @click="getNewEnrollementToApproval(ActiveNavBarTab, 1)"></vs-button>
              </vs-col>
            </vs-row>
            <vs-row>
              <vs-col vs-type="flex" vs-justify="center" class="mb-1">
                <vs-button color="#0044BA" icon="search" size="small"
                  @click="getNewEnrollementToApproval(ActiveNavBarTab, 1)">Search</vs-button>
              </vs-col>
            </vs-row>
          </vx-card>
        </vs-col>
      </vs-row>
    </div>
    <div>
      <vx-card class="mt-5">
        <div style="max-width: 510px" class="ml-3">
          <vs-navbar text-color="black" color="#ffffff" active-text-color="#FFFFFF" class="approved_navbar"
            v-model="ActiveNavBarTab">
            <vs-navbar-item index="pending">
              <a>Pending</a>
            </vs-navbar-item>
            <vs-navbar-item index="approved">
              <a>Approved</a>
            </vs-navbar-item>
            <vs-navbar-item index="auto_approved">
              <a>Auto-Approved</a>
            </vs-navbar-item>
            <vs-navbar-item index="rejected" style="margin-right: 3%">
              <a>Rejected</a>
            </vs-navbar-item>
          </vs-navbar>
        </div>
        <div class="mt-3">
          <div v-if="enrollment_details.length != 0">
            <enrollment-approval-table :enrollment_details="enrollment_details"
              :ActiveNavBarTab="ActiveNavBarTab"></enrollment-approval-table>
          </div>
          <div v-if="enrollment_details.length == 0" style="color: grey; margin: 5px" class="mt-5">
            <center>NO DATA AVAILABLE</center>
          </div>
        </div>
        <vs-row style="margin-top: 3%">
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="12">
            <vs-pagination :total="tablelinks" v-model="currentTablePage"></vs-pagination>
          </vs-col>
        </vs-row>
      </vx-card>
    </div>
  </div>
</template>

<script>
import EventBus from "../components/eventbus";
import datatable from "../components/pagesComponents/EnrollmentApprovalDatatable.vue";
import axios from "axios";
import constants from "../../constants.json";
// import EventBus from "../components/eventbus";
import vSelect from "vue-select";

import DateRangePicker from "vue2-daterange-picker";
import "vue-tree-halower/dist/halower-tree.min.css";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import "vue-select/dist/vue-select.css";
import EnrollmentApprovalTable from "./EnrollmentApprovalTable.vue";
import { VTree, VSelectTree } from "vue-tree-halower";
import Treeselect from "@riophae/vue-treeselect";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
export default {
  components: {
    datatable,
    VTree,
    vSelect,
    DateRangePicker,
    Datepicker,
    VSelectTree,
    Treeselect,
    EnrollmentApprovalTable,
  },
  data() {
    return {
      dropdownKey: 0,
      currentTablePage: 1,
      tablelinks: 0,
      city: "",
      cities: [],
      mode_of_study: null,
      type_of_enrolment_options: [
        { label: "Classroom Training", value: "classroom_training" },
        { label: "Online Training", value: "online_training" },
        { label: "Online and Classroom", value: "online_classroom" },
        // "Books",
        // "Assistance"
      ],
      filter: "pending",
      tabColor: "warning",
      can_id: "",
      batch_code: "",
      person_name: "",
      mobile: "",

      candidate_email: "",
      table_data_showing: false,
      ActiveNavBarTab: "pending",
      identity: "",
      candidate_name: "",
      candidate_number: "",
      lastCallDateateRange: {
        startDate: null,
        endDate: null,
      },
      nextCallDateRange: {
        startDate: null,
        endDate: null,
      },
      enrollmentDateRange: {
        startDate: null,
        endDate: null,
      },
      initSelectedCity: [],
      treeDataCity: [
      {
          id: "All",
          label: "All",
          children: [],
        },
      ],
      initSelectedCC: [],
      treeDataCC: [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
      initSelectedSpoc: [],
      treeDataSpoc: [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
      initSelectedSC: [],
      treeDataSC: [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
      initCourses: [],
      treeDataCourses: [
        {
          id: "All",
          label: "All",
          children: [
            {
              id: "CPA",
              label: "CPA",
            },
            {
              id: "CPA-AA",
              label: "CPA-AA",
            },
             {
              id: "USP",
              label: "USP",
            },
            // {
            //   id: "AICPA",
            //   label: "AICPA",
            // },
            // {
            //   id: "CISA",
            //   label: "CISA",
            // },
            // {
            //   id: "EA",
            //   label: "EA",
            // },
            // {
            //   id: "CIA",
            //   label: "CIA",
            // },
            {
              id: "CMA",
              label: "CMA",
            },
            {
              id: "CFA",
              label: "CFA",
            },
            {
              id: "FRM",
              label: "FRM",
            },
            {
              id: "RPO",
              label: "RPO",
            },
            // {
            //   id: "IIML-FA",
            //   label: "IIML-FA",
            // },
            // {
            //   id: "IIML-FT",
            //   label: "IIML-FT",
            // },
            // {
            //   id: "IIML-SF",
            //   label: "IIML-SF",
            // },
            // {
            //   id: "IIML-DA",
            //   label: "IIML-DA",
            // },
            // {
            //   id: "IIML-HR",
            //   label: "IIML-HR",
            // },
            // {
            //   id: "IITR-BF",
            //   label: "IITR-BF",
            // },
            // {
            //   id: "IITR-DB",
            //   label: "IITR-DB",
            // },
            // {
            //   id: "IITM-AA",
            //   label: "IITM-AA",
            // },
            // { id: "IIMK-CX", label: "IIMK-CX" },
            // { id: "IITM-FS", label: "IITM-FS" },
            // { id: "IITR-CC", label: "IITR-CC" },
            // { id: "IIMK-FT", label: "IIMK-FT" },
            // { id: "IIML-AB", label: "IIML-AB" },
            // { id: "IIML-SH", label: "IIML-SH" },
            // { id: "IITJ-DE", label: "IITJ-DE" },
            // { id: "XLRI-HR", label: "XLRI-HR" },
            // { id: "XLRI-SH", label: "XLRI-SH" },
            // { id: "IIMI-BA", label: "IIMI-BA" },
            // { id: "IIMI-AA", label: "IIMI-AA" },
            // { id: "XLRI-DM", label: "XLRI-DM" },
            // {
            //   id: "IIML-PM",
            //   label: "IIML-PM",
            // },
          ],
        },
      ],
      filter_data: [],
      enrollment_count: 0,
      dates: null,
      cityData: [],
      selectedCCData: [],
      selectedSpocData: [],
      selectedSCData: [],
      selectedCourseData: [],
      filter_spoc_ids: [],
      enrollment_details: [],
      show_options_data: true,
      login_spoc_id:"",

    };
  },
  watch: {
    dates(val){
      if(val != null){
        this.enrollmentDateRange={
        startDate: null,
        endDate: null,
      }
      }
    },
    enrollmentDateRange(val){
      if(val.endDate !=null && val.startDate != null){
        this.dates = null
      }
    },
    ActiveNavBarTab(val) {
      this.getNewEnrollementToApproval(val, 1)
    },
    currentTablePage(val) {
      this.getNewEnrollementToApproval(this.ActiveNavBarTab, val)
    },
    initSelectedCity: function () {
      this.mergeAllSearch();
      this.getOptionsData();
    },
    initSelectedCC: function () {
      this.mergeAllSearch();
      this.getOptionsData();
    },
    initSelectedSpoc: function () {
      this.mergeAllSearch();
      this.getOptionsData();
    },
    initSelectedSC: function () {
      this.mergeAllSearch();
      this.getOptionsData();
    },
    initCourses: function (val) {
      this.mergeAllSearch();
      if (val.length != 0) {
        this.courses = [];
        if (val == "All") {
          this.treeDataCourses[0].children.forEach((child) => {
            this.courses.push(child.label);
          });
        } else {
          this.courses.push(val);
        }
      } else {
        this.courses = [];
      }
    },
  },
  mounted() {
    this.login_spoc_id = localStorage.getItem("spoc_id");
    EventBus.$on("reloadapprovalsdata", () => {
      this.getNewEnrollementToApproval('pending', 1)
     });
    // this.getAllActiveSpocsForDashboard();
    this.getAllSpoc();
    this.getNewEnrollementToApproval('pending', 1)
  },
  methods: {
    getAllSpoc() {
      this.$vs.loading();
      let url = `${constants.SERVER_API}getAllSpocs`;
      axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.userAccessToken}`,
        },
      })
        .then((response) => {
          console.log("Get All Spoc", response.data.spocs)
          this.filter_data = response.data.spocs;
          this.getUinqeCityData(this.filter_data);
          this.getCCData(this.filter_data);
          this.getSpocsData(this.filter_data);
          this.getSCData(this.filter_data);
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.handleError(error);
        });
    },
    getUinqeCityData(data) {
      this.treeDataCity[0].children = [];
      const city_data = [...new Set(data.map((item) => item.city))].sort();
      let city_options = ["Ahmedabad","Bangalore","Chennai","Delhi","Ernakulam","Hyderabad","Kolkata","Mumbai","Pune",]
      for (let i = 0; i < city_data.length; i++) {
        const element = city_data[i];
        const obj = {
          id: element,
          label: element,
        };
        if(city_options.includes(element)){
          this.treeDataCity[0].children.push(obj);
        }
      }
    },
    getCCData(data) {
      this.treeDataCC[0].children = [];
      const cc_data = [...new Set(data.map((item) => item.city_classification))].sort();
      for (let i = 0; i < cc_data.length; i++) {
        const element = cc_data[i];
        if (element != null) {
          // const obj = {
          //   id: element,
          //   label: element,
          // };
          // this.treeDataCC[0].children.push(obj);
          if(this.login_spoc_id == 132 ||this.login_spoc_id == 937||this.login_spoc_id == 39){
                      let obj = {
                      id: element,
                      label:element,  
                    };
                      this.treeDataCC[0].children.push(obj);
              }
               else{
                    if(element.substring(0,1) != 'X'){
                     let obj = {
                     id: element,
                     label: element,
                    };
                       this.treeDataCC[0].children.push(obj);
                   }
                }
        }
      }
    },
    getSpocsData(data) {
      this.treeDataSpoc[0].children = [];
      const spoc_data = [...new Set(data.map((item) => item.full_name))].sort();
      for (let i = 0; i < spoc_data.length; i++) {
        const element = spoc_data[i];
        if (element != null) {
          // const obj = {
          //   id: element,
          //   label: element,
          // };
          // this.treeDataSpoc[0].children.push(obj);
          if(this.login_spoc_id == 132 ||this.login_spoc_id == 937 || this.login_spoc_id == 39){
                      let obj = {
                      id: element,
                      label:element,  
                    };
                      this.treeDataSpoc[0].children.push(obj);
              }
               else{
                    if(element.substring(0,1) != 'X'){
                     let obj = {
                     id: element,
                     label: element,
                    };
                       this.treeDataSpoc[0].children.push(obj);
                   }
                }
        }
      }
    },
    getSCData(data) {
      this.treeDataSC[0].children = [];
      const sc_data = [...new Set(data.map((item) => item.user_tag))].sort();
      for (let i = 0; i < sc_data.length; i++) {
        const element = sc_data[i];
        if (element != null) {
          // const obj = {
          //   id: element,
          //   label: element,
          // };
          // this.treeDataSC[0].children.push(obj);
          if(this.login_spoc_id == 132 ||this.login_spoc_id == 937 || this.login_spoc_id == 39){
                      let obj = {
                      id: element,
                      label:element,  
                    };
                      this.treeDataSC[0].children.push(obj);
              }
               else{
                    if(element.substring(0,1) != 'X'){
                     let obj = {
                     id: element,
                     label: element,
                    };
                       this.treeDataSC[0].children.push(obj);
                   }
                }
        }
      }
    },
    datatoTimestamp(stringyDate) {
      var dateyDate = new Date(this.timetoTimestamp(stringyDate));
      var ms = dateyDate.valueOf();
      return ms;
    },
    timetoTimestamp(incoming) {
      return new Date(incoming).setHours(0, 0, 0, 0) / 1000;
    },
    refreshData() {
      this.identity = "";
      this.candidate_name = "";
      this.candidate_number = "";
      this.candidate_email = "";
      this.cityData = [];
      this.selectedCCData = [];
      this.selectedSpocData = [];
      this.selectedSCData = [];
      this.selectedCourseData = [];
      this.initSelectedCity = [];
      this.initSelectedCC = [];
      this.initSelectedSpoc = [];
      this.initSelectedSC = [];
      this.initCourses = [];
      this.filter_spoc_ids = [];
      this.ActiveNavBarTab = 'pending';
      this.clearDateFilter();
      this.getNewEnrollementToApproval('pending', 1);
    },
    getOptionsData() {
      if (this.initSelectedCity.length != 0 && this.initSelectedCC.length == 0 && this.initSelectedSpoc.length == 0 && this.initSelectedSC.length == 0) {
        this.getUinqeCityData(this.filter_data);
        this.getCityWisesData();
      }else if (this.initSelectedCity.length == 0 && this.initSelectedCC.length != 0 && this.initSelectedSpoc.length == 0 && this.initSelectedSC.length == 0) {
        this.getCCData(this.filter_data);
        this.getCCWisesData();
      }else if (this.initSelectedCity.length == 0 && this.initSelectedCC.length == 0 && this.initSelectedSpoc.length != 0 && this.initSelectedSC.length == 0) {
        this.getSpocsData(this.filter_data);
        this.getSpocWisesData();
      }else if (this.initSelectedCity.length == 0 && this.initSelectedCC.length == 0 && this.initSelectedSpoc.length == 0 && this.initSelectedSC.length != 0) {
        this.getSCData(this.filter_data);
        this.getSCWisesData();
      }else if (this.initSelectedCity.length == 0 && this.initSelectedCC.length == 0 && this.initSelectedSpoc.length == 0 && this.initSelectedSC.length == 0) {
        this.getCCData(this.filter_data);
        this.getSpocsData(this.filter_data);
        this.getSCData(this.filter_data);
        this.getUinqeCityData(this.filter_data);
      }
    },
    getNewEnrollementToApproval(filter, page) {
      this.$vs.loading();
      if(this.selectedSpocData.length !==0){
        this.getAllSpocId();
      }
      var year = null;
      var month = null;
      if (this.dates != null) {
        month = moment(this.dates).format("MM");
        year = moment(this.dates).format("YYYY")
      }
      var enrollment_from = null;
      var enrollment_to = null;
      if (
        this.enrollmentDateRange.startDate !== null &&
        this.enrollmentDateRange.endDate !== null
      ) {
        enrollment_from = moment(this.enrollmentDateRange.startDate).format("DD/MM/YYYY");
        enrollment_to = moment(this.enrollmentDateRange.endDate).format("DD/MM/YYYY");
      }
      // var last_call_from = null;
      // var last_call_to = null;
      // if (
      //   this.lastCallDateateRange.startDate !== null &&
      //   this.lastCallDateateRange.endDate !== null
      // ) {
      //   last_call_from = moment(
      //     this.lastCallDateateRange.startDate
      //   ).format("DD/MM/YYYY");
      //   last_call_to = moment(this.lastCallDateateRange.endDate).format("DD/MM/YYYY");
      // }
      let obj = {
        filter: filter,
        person_name: this.candidate_name,
        mobile: this.candidate_number,
        identity: this.identity,
        email: this.candidate_email,
        courses: this.selectedCourseData.join(),
        enrollment_month: month,
        enrollment_year: year,
        // last_call_from_date: last_call_from,
        // last_call_to_date: last_call_to,
        enrollment_from: enrollment_from,
        enrollment_to: enrollment_to,
        // spoc_ids: this.filter_spoc_ids.join(),
        city:this.cityData.join(),
        city_classification: this.selectedCCData.join(),
        spoc_id:this.filter_spoc_ids.join(),
        spoc_classification: this.selectedSCData.join()
      }
      // let url = `https://crm.milesforce.com/api/getEnrollmentsToApprove?page=${page}`
      let url = `${constants.SERVER_API}getEnrollmentsToApprove?page=${page}`;
      axios.get(url, {
        params: obj,
        headers: {
          Authorization: `Bearer ${localStorage.userAccessToken}`,
        },
      })
        .then((response) => {
          this.enrollment_count = response.data.enrollments.total;
          this.table_data_showing = true,
            this.enrollment_details = response.data.enrollments.data;
          this.tablelinks = response.data.enrollments.last_page;
          this.currentTablePage = response.data.enrollments.current_page;
          console.log("Enollment Data", response.data)
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.handleError(error);
        });
      console.log("get Dataa", page, this.filter_spoc_ids.join());
    },
    customFormatter(date) {
      return moment(date).format("MMM-YYYY");
    },
    showTreeBox() {
      console.log("clicked");
      return this.treeDataBDE;
    },
    getSpocWisesData() {
      const city_data = [];
      const CC_data = [];
      const SC_data = [];
      this.treeDataSC[0].children = [];
      this.treeDataCC[0].children = [];
      this.treeDataCity[0].children = [];
      this.filter_data.forEach((raw) => {
        this.selectedSpocData.forEach((spoc) => {
          if (raw.full_name == spoc) {
            city_data.push(raw.city);
          }
          
        });
      });
      this.filter_data.forEach((raw) => {
        this.selectedSpocData.forEach((spoc) => {
          if(raw.full_name == spoc){
            CC_data.push(raw.city_classification)
          }
        });
      });
      this.filter_data.forEach((raw) => {
              this.selectedSpocData.forEach((spoc)=>{
                if(raw.full_name == spoc){
                  SC_data.push(raw.user_tag);
                }
              })
      });
      const city = [...new Set(city_data.map((item) => item))];
      for (let index = 0; index < city.length; index++) {
        const data = city[index];
        if (data != null) {
          const obj = {
            id: data,
            label: data,
          };
          this.treeDataCity[0].children.push(obj);
        }
      }
      const cc = [...new Set(CC_data.map((item) => item))];
      for (let index = 0; index < cc.length; index++) {
        const data = cc[index];
        if (data != null) {
          const obj = {
            id: data,
            label: data,
          };
          this.treeDataCC[0].children.push(obj);
        }
      }
      const sc = [...new Set(SC_data.map((item) => item))];
      for (let index = 0; index < sc.length; index++) {
        const data = sc[index];
        if (data != null) {
          const obj = {
            id: data,
            label: data,
          };
          this.treeDataSC[0].children.push(obj);
        }
      }
    },
    getCCWisesData() {
      const city_data = [];
      const spoc_data = [];
      const SC_data = [];
      this.treeDataSC[0].children = [];
      this.treeDataSpoc[0].children = [];
      this.treeDataCity[0].children= [];
      this.filter_data.forEach((raw) => {
        this.initSelectedCC.forEach((cc) => {
          if (raw.city_classification == cc) {
            city_data.push(raw.city);
          }
        });
      });
      this.filter_data.forEach((raw) => {
        this.initSelectedCC.forEach((cc) => {
            if (raw.city_classification == cc) {
              spoc_data.push(raw.full_name);
            }
        });
      });
      this.filter_data.forEach((raw) => {
        this.initSelectedCC.forEach((cc) => {
            if(raw.city_classification == cc) {
              SC_data.push(raw.user_tag);
            }
        });
      });
      const city = [...new Set(city_data.map((item) => item))];
      for (let index = 0; index < city.length; index++) {
        const data = city[index];
        if (data != null) {
          const obj = {
            id: data,
            label: data,
          };
          this.treeDataCity[0].children.push(obj);
        }
      }
      const spoc = [...new Set(spoc_data.map((item) => item))];
      for (let index = 0; index < spoc.length; index++) {
        const data = spoc[index];
        if (data != null) {
          const obj = {
            id: data,
            label: data,
          };
          this.treeDataSpoc[0].children.push(obj);
        }
      }
      const sc = [...new Set(SC_data.map((item) => item))];
      for (let index = 0; index < sc.length; index++) {
        const data = sc[index];
        if (data != null) {
          const obj = {
            id: data,
            label: data,
          };
          this.treeDataSC[0].children.push(obj);
        }
      }
    },
    getCityWisesData() {
      const cc_data = [];
      const spoc_data = [];
      const SC_data = [];
      this.treeDataSC[0].children = [];
      this.treeDataSpoc[0].children = [];
      this.treeDataCC[0].children = [];
      this.filter_data.forEach((raw) => {
        this.cityData.forEach((city) => {
          if (raw.city == city) {
            cc_data.push(raw.city_classification);
          }
        });
      });
      this.filter_data.forEach((raw) => {
        this.cityData.forEach((city) => {
          if (raw.city == city) {
            spoc_data.push(raw.full_name);
          }
        });
      });
      this.filter_data.forEach((raw) => {
        this.cityData.forEach((city) => {
          if (raw.city == city) {
            SC_data.push(raw.user_tag);
          }
        });
      });
      const cc = [...new Set(cc_data.map((item) => item))];
      for (let index = 0; index < cc.length; index++) {
        const data = cc[index];
        if (data != null) {
          const obj = {
            id: data,
            label: data,
          };
          this.treeDataCC[0].children.push(obj);
        }
      }
      const spoc = [...new Set(spoc_data.map((item) => item))];
      for (let index = 0; index < spoc.length; index++) {
        const data = spoc[index];
        if (data != null) {
          const obj = {
            id: data,
            label: data,
          };
          this.treeDataSpoc[0].children.push(obj);
        }
      }
      const sc = [...new Set(SC_data.map((item) => item))];
      for (let index = 0; index < sc.length; index++) {
        const data = sc[index];
        if (data != null) {
          const obj = {
            id: data,
            label: data,
          };
          this.treeDataSC[0].children.push(obj);
        }
      }
    },
    getSCWisesData() {
      const cc_data = [];
      const spoc_data = [];
      const city_data = [];
      this.treeDataCity[0].children = [];
      this.treeDataSpoc[0].children = [];
      this.treeDataCC[0].children = [];
      this.filter_data.forEach((raw) => {
        this.selectedSCData.forEach((sc)=>{
          if(raw.user_tag == sc){
            city_data.push(raw.city);
          }
        });
      });
      this.filter_data.forEach((raw) => {
        this.selectedSCData.forEach((sc)=>{
          if(raw.user_tag == sc){
            spoc_data.push(raw.full_name);
          }
        });
      });
      this.filter_data.forEach((raw) => {
        this.selectedSCData.forEach((sc)=>{
          if(raw.user_tag == sc){
            cc_data.push(raw.city_classification);
          }
        });
      });
      const city = [...new Set(city_data.map((item) => item))];
      for (let index = 0; index < city.length; index++) {
        const data = city[index];
        if (data != null) {
          const obj = {
            id: data,
            label: data,
          };
          this.treeDataCity[0].children.push(obj);
        }
      }
      const cc = [...new Set(cc_data.map((item) => item))];
      for (let index = 0; index < cc.length; index++) {
        const data = cc[index];
        if (data != null) {
          const obj = {
            id: data,
            label: data,
          };
          this.treeDataCC[0].children.push(obj);
        }
      }
      const spoc = [...new Set(spoc_data.map((item) => item))];
      for (let index = 0; index < spoc.length; index++) {
        const data = spoc[index];
        if (data != null) {
          const obj = {
            id: data,
            label: data,
          };
          this.treeDataSpoc[0].children.push(obj);
        }
      }
    },
    getSpocDetails() {
      var spoc = [];
      this.treeDataSpoc[0].children = [];
      this.filter_data.forEach((raw) => {
        this.cityData.forEach((city) => {
          this.selectedCCData.forEach((cc) => {
            if (raw.city == city && raw.city_classification == cc) {
              spoc.push(raw.full_name);
            }
          });
        });
      });
      const value = [...new Set(spoc.map((item) => item))];
      for (let index = 0; index < value.length; index++) {
        const data = value[index];
        if (data != null) {
          const obj = {
            id: data,
            label: data,
          };
          this.treeDataSpoc[0].children.push(obj);
        }
      }
    },
    getAllSpocId() {
      this.filter_spoc_ids = [];
      this.filter_data.forEach((raw) => {
        this.selectedSpocData.forEach((spoc) => {
            if (raw.full_name == spoc) {
                this.filter_spoc_ids.push(raw.id);
              }
            })
      });
      // this.for_notify = "";
      // console.log("selectedSCData", this.selectedSCData);
      // this.filter_data.forEach((raw) => {
      //   if (
      //     this.cityData.length != 0
      //   ) {
      //     this.cityData.forEach((city) => {
      //       if (raw.city == city) {
      //         this.filter_spoc_ids.push(raw.id);
      //       }
      //     });
      //   }
      //    if (
      //     this.selectedSpocData.length != 0
      //   ) {
      //     this.selectedSpocData.forEach((spoc) => {
      //       if (raw.full_name == spoc) {
      //           this.filter_spoc_ids.push(raw.id);
      //         }
      //     });
      //   }
      //    if (
      //     this.selectedCCData.length != 0 
      //   ) {
      //     this.selectedCCData.forEach((cc) => {
      //       if (raw.city_classification == cc) {
      //           this.filter_spoc_ids.push(raw.id);
      //         }
      //     });
      //   }
      //    if (
      //     this.cityData.length == 0 &&
      //     this.selectedSCData.length != 0 
      //   ) {
      //     this.selectedSCData.forEach((sc) => {
      //       if (raw.user_tag == sc) {
      //           this.filter_spoc_ids.push(raw.id);
      //         }
      //     });
      //   }
      //   else if (
      //     this.cityData.length != 0 &&
      //     this.selectedSCData.length !== 0 &&
      //     this.selectedCCData.length == 0 &&
      //     this.selectedSpocData.length == 0
      //   ) {
      //     this.cityData.forEach((city) => {
      //       this.selectedSCData.forEach((sc) => {
      //         if (raw.city == city && raw.user_tag == sc) {
      //           this.filter_spoc_ids.push(raw.id);
      //         } else {
      //           this.for_notify = "empty";
      //         }
      //       });
      //     });
      //   } else if (
      //     this.cityData.length != 0 &&
      //     this.selectedSCData.length !== 0 &&
      //     this.selectedCCData.length != 0 &&
      //     this.selectedSpocData.length == 0
      //   ) {
      //     this.cityData.forEach((city) => {
      //       this.selectedSCData.forEach((sc) => {
      //         this.selectedCCData.forEach((cc) => {
      //           if (
      //             raw.city == city &&
      //             raw.user_tag == sc &&
      //             raw.city_classification == cc
      //           ) {
      //             this.filter_spoc_ids.push(raw.id);
      //           } else {
      //             this.for_notify = "empty";
      //           }
      //         });
      //       });
      //     });
      //   } else if (
      //     this.cityData.length != 0 &&
      //     this.selectedCCData.length != 0 &&
      //     this.selectedSpocData.length !== 0 &&
      //     this.selectedSCData.length == 0
      //   ) {
      //     this.cityData.forEach((city) => {
      //       this.selectedCCData.forEach((cc) => {
      //         this.selectedSpocData.forEach((spoc) => {
      //           if (
      //             raw.city == city &&
      //             raw.city_classification == cc &&
      //             raw.full_name == spoc
      //           ) {
      //             this.filter_spoc_ids.push(raw.id);
      //           }
      //         });
      //       });
      //     });
      //   } else if (
      //     this.cityData.length != 0 &&
      //     this.selectedCCData.length != 0 &&
      //     this.selectedSpocData.length == 0 &&
      //     this.selectedSCData.length == 0
      //   ) {
      //     this.cityData.forEach((city) => {
      //       this.selectedCCData.forEach((cc) => {
      //         if (raw.city == city && raw.city_classification == cc) {
      //           this.filter_spoc_ids.push(raw.id);
      //         }
      //       });
      //     });
      //   } else if (
      //     this.cityData.length != 0 &&
      //     this.selectedCCData.length != 0 &&
      //     this.selectedSpocData.length == 0 &&
      //     this.selectedSCData.length !== 0
      //   ) {
      //     this.cityData.forEach((city) => {
      //       this.selectedCCData.forEach((cc) => {
      //         this.selectedSCData.forEach((sc) => {
      //           if (
      //             raw.city == city &&
      //             raw.city_classification == cc &&
      //             raw.user_tag == sc
      //           ) {
      //             this.filter_spoc_ids.push(raw.id);
      //           } else {
      //             this.for_notify = "empty";
      //           }
      //         });
      //       });
      //     });
      //   } else if (
      //     this.cityData.length != 0 &&
      //     this.selectedCCData.length != 0 &&
      //     this.selectedSCData.length !== 0 &&
      //     this.selectedSpocData.length !== 0
      //   ) {
      //     this.cityData.forEach((city) => {
      //       this.selectedCCData.forEach((cc) => {
      //         this.selectedSCData.forEach((sc) => {
      //           this.selectedSpocData.forEach((spoc) => {
      //             if (
      //               raw.city == city &&
      //               raw.city_classification == cc &&
      //               raw.user_tag == sc &&
      //               raw.full_name == spoc
      //             ) {
      //               this.filter_spoc_ids.push(raw.id);
      //             } else {
      //               this.for_notify = "empty";
      //             }
      //           });
      //         });
      //       });
      //     });
      //   } else if (this.selectedSCData.length !== 0) {
      //     this.selectedSCData.forEach((sc) => {
      //       if (raw.user_tag == sc) {
      //         this.filter_spoc_ids.push(raw.id);
      //       }
      //     });
      //   }
      // });
    },
    clearDateFilter() {
      this.lastCallDateateRange = {
        startDate: null,
        endDate: null,
      };
      this.enrollmentDateRange = {
        startDate: null,
        endDate: null,
      };
      this.dates = null;
    },
    mergeAllSearch() {
      this.cityData = [];
      this.initSelectedCity.forEach((sort) => {
        if (sort === "All") {
          this.treeDataCity[0].children.forEach((child) => {
            this.cityData.push(child.label);
          });
        } else {
          this.cityData.push(sort);
        }
      });
      this.selectedCCData = [];
      this.initSelectedCC.forEach((sort) => {
        if (sort === "All") {
          this.treeDataCC[0].children.forEach((child) => {
            this.selectedCCData.push(child.label);
          });
        } else {
          this.selectedCCData.push(sort);
        }
      });
      this.selectedSpocData = [];
      this.initSelectedSpoc.forEach((sort) => {
        if (sort === "All") {
          this.treeDataSpoc[0].children.forEach((child) => {
            this.selectedSpocData.push(child.label);
          });
        } else {
          this.selectedSpocData.push(sort);
        }
      });
      this.selectedSCData = [];
      this.initSelectedSC.forEach((sort) => {
        if (sort === "All") {
          this.treeDataSC[0].children.forEach((child) => {
            this.selectedSCData.push(child.label);
          });
        } else {
          this.selectedSCData.push(sort);
        }
      });
      this.selectedCourseData = [];
      this.initCourses.forEach((sort) => {
        if (sort === "All") {
          this.treeDataCourses[0].children.forEach((child) => {
            this.selectedCourseData.push(child.label);
          });
        } else {
          this.selectedCourseData.push(sort);
        }
      });
    },
    removechip(chip, name) {
      console.log("jfdkfjdkljf",this.initSelectedCity)
      if (name == "city") {
        if (this.initSelectedCity.indexOf(chip) === -1) {
          this.initSelectedCity = [];
          this.treeDataCity.forEach((child) => {
            if (child.label !== chip) {
              this.initSelectedCity.push(child.label);
              this.cityData.push(child.label);
            }
          });
        } else {
          this.initSelectedCity.splice(this.initSelectedCity.indexOf(chip), 1);
          this.cityData.splice(this.cityData.indexOf(chip), 1);
        }
      } else if (name == "cc") {
        if (this.initSelectedCC.indexOf(chip) === -1) {
          this.initSelectedCC = [];
          this.treeDataCC[0].children.forEach((child) => {
            if (child.label !== chip) {
              this.initSelectedCC.push(child.label);
              this.selectedCCData.push(child.label);
            }
          });
        } else {
          this.initSelectedCC.splice(this.initSelectedCC.indexOf(chip), 1);
          this.selectedCCData.splice(this.selectedCCData.indexOf(chip), 1);
        }
      } else if (name == "spoc") {
        if (this.initSelectedSpoc.indexOf(chip) === -1) {
          this.initSelectedSpoc = [];
          this.treeDataSpoc[0].children.forEach((child) => {
            if (child.label !== chip) {
              this.initSelectedSpoc.push(child.label);
              this.selectedSpocData.push(child.label);
            }
          });
        } else {
          this.initSelectedSpoc.splice(this.initSelectedSpoc.indexOf(chip), 1);
          this.selectedSpocData.splice(this.selectedSpocData.indexOf(chip), 1);
        }
      } else if (name == "sc") {
        if (this.initSelectedSC.indexOf(chip) === -1) {
          this.initSelectedSC = [];
          this.treeDataSC[0].children.forEach((child) => {
            if (child.label !== chip) {
              this.initSelectedSC.push(child.label);
              this.selectedSCData.push(child.label);
            }
          });
        } else {
          this.initSelectedSC.splice(this.initSelectedSC.indexOf(chip), 1);
          this.selectedSCData.splice(this.selectedSCData.indexOf(chip), 1);
        }
      } else if (name == "course") {
        if (this.initCourses.indexOf(chip) === -1) {
          this.initCourses = [];
          this.treeDataCourses[0].children.forEach((child) => {
            if (child.label !== chip) {
              this.initCourses.push(child.label);
              this.selectedCourseData.push(child.label);
            }
          });
        } else {
          this.initCourses.splice(this.initCourses.indexOf(chip), 1);
          this.selectedCourseData.splice(
            this.selectedCourseData.indexOf(chip),
            1
          );
        }
      }
    },
  },
};
</script>

<style lang="scss">
.vs--searchable .vs__dropdown-toggle {
  height: 38px;
}

.approval_search_container {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  width: 100%;
  min-height: 394.69px;
}

.enrollment_count {
  background: #0044ba;
  border-radius: 15px 0px;
  width: 260.62px;
  height: 37.8px;
  display: flex;
  justify-content: space-between;
  padding-inline: 1%;
  align-items: center;
}

.enrollment_text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
}

.none_padding {
  padding: 0px;
}

.filter-heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
}

.enrollment-tree-drop#citys>.vs-con-dropdown {
  background: #eae5fa;
  border: 1px solid rgba(46, 13, 146, 0.5);
  width: 90%;
  border-radius: 10px;
  margin-inline: 2%;
}

.enrollment-tree-drop#cc>.vs-con-dropdown {
  background: #fae1fe;
  border: 1px solid #de56f4;
  border-radius: 10px;
  width: 90%;
  margin-inline: 2%;
}

.enrollment-tree-drop#spoc>.vs-con-dropdown {
  background: rgba(188, 235, 250, 0.69);
  border: 1px solid #1fabd8;
  border-radius: 10px;
  width: 90%;
  margin-inline: 2%;
}

.enrollment-tree-drop#sc>.vs-con-dropdown {
  background: #b4edff;
  border: 1px solid #13a0cd;
  border-radius: 10px;
  width: 90%;
  margin-inline: 2%;
}

.enrollment-tree-drop#course>.vs-con-dropdown {
  background: rgba(255, 194, 194, 0.69);
  border: 1px solid #ec6868;
  border-radius: 10px;
  width: 90%;
  margin-inline: 2%;
}

.enrollment-tree-drop#Visitor>.vs-con-dropdown {
  background: rgba(255, 194, 194, 0.69);
  border: 1px solid #ec6868;
  border-radius: 10px;
  width: 100%;
  line-height: 35px;
}

.enrollment-tree-drop#level>.vs-con-dropdown {
  background: rgba(255, 194, 194, 0.69);
  border: 1px solid #ec6868;
  border-radius: 10px;
  width: 100%;
  line-height: 35px;
}

.enrollment-tree-drop#status>.vs-con-dropdown {
  background: #f5e1ce;
  border: 1px solid #f1a964;
  border-radius: 10px;
  width: 100%;
}

.enrollment-tree-drop#type>.vs-con-dropdown {
  background: #f1ad78;
  border: 1px solid #f38630;
  border-radius: 10px;
  width: 100%;
}

.enrollment-tree-drop#level_drop>.vs-con-dropdown {
  background: #99eee3;
  border: 1px solid #14d685;
  border-radius: 10px;
  width: 100%;
}

.enrollment-tree-drop>.vs-con-dropdown {
  width: 100%;
  min-height: 32px;
  border-radius: 10px;
  padding-inline: 5px;
  color: #000000;
  display: flex !important;
  align-items: center;
}

.approval_options_filter {
  min-height: 351.22px;
  margin: 2px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.25);
}

.approval_options_filter .vx-card__body {
  padding: 5px !important;
}

.alignment {
  text-align: initial;
}

.ele-inside-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  padding-inline-start: 10px;
}

.ele-inside-text>i {
  color: rgba(0, 0, 0, 0.4);
}

.a-iconns>.material-icons {
  margin-left: auto;
}

.a-icon {
  outline: none;
  text-decoration: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

i {
  font-size: 18px;
}

.a-iconns {
  color: #000;
  font-weight: 500;
  font-size: 14px;
  width: 99%;
  display: flex;
  justify-content: flex-end !important;
}

.enrollment_date>.reportrange-text {
  background: #ffffff;
  border: 1px solid #0044ba;
  box-shadow: 0px 4px 5px rgb(0 0 0 / 16%);
  min-height: 44.47px;
  border-radius: 10px;
}

.date_filter_heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px; 
}

.enrollment-date-picker>div input {
  background: #ffffff;
  -webkit-padding-start: 9%;
  display: block;
  border-radius: 5px;
  min-height: 44.47px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  width: 100%;
  border: 1px solid #0044ba;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.16);
  color: #000000 !important;
  border-radius: 10px;
}

.aplproval_search_criteria {
  width: 100%;
  background: #ffffff;
  min-height: 139px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 15px;
}

.Criteria_heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #000000;
}

.approval-option-side {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
}

.criteria_chip {
  border-radius: 5px;
}

.criteria_chip .vs-chip--close {
  background: transparent !important;
  color: #000000;
}

.criteria_chip .vs-chip--text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;
  text-align: center;
  color: #000000;
}

.approval-input-filter {
  width: 100% !important;
}

.approval-input-filter.vs-con-input .input-span-placeholder {
  font-size: 14px;
  margin-left: 1%;
}

.approval-input-filter>.vs-con-input>.vs-inputx {
  height: 40px;
  border: 1px solid #0044ba !important;
  border-radius: 15px;
}

.vs-dropdown--menu {
  width: 300px;
}

.desc-border-box3 {
  border: 1px solid #0044ba;
}

.approved_navbar .vs-con-items {
  display: contents;
}

.approved_navbar {
  background: #ffffff;
  border: 1px solid #0044ba;
  border-radius: 10px;
  z-index: 1000;
}

.approved_navbar li.vs-navbar--item a {
  padding: 3px 17px;
  margin-block: 7px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.approved_navbar .vs-navbar--item:hover {
  color: black !important;
}
</style>
